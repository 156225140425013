import Vue from "vue";
import firebase from "firebase/app";
import App from "./App.vue";
import router from "./router";
//import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

/*Vue.use(
  VueGtag,
  {
    config: { id: "UA-153747717-1", params: { anonymize_ip: true } }
  },
  router
);*/

const firebaseConfig = {
  apiKey: "AIzaSyCByyaSQWUCzQUlvXXd8ObhLtGFUhkkj1Q",
  authDomain: "lu-pop-up.firebaseapp.com",
  databaseURL: "https://lu-pop-up.firebaseio.com",
  projectId: "lu-pop-up",
  storageBucket: "lu-pop-up.appspot.com",
  messagingSenderId: "693307529623",
  appId: "1:693307529623:web:d4239c95bc170abec54cd2",
  measurementId: "G-PNE8GNL8Da1"
};

firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
