<template>
  <div class="form mt-4">
    <form v-on:submit.prevent="onSubmit">
      <div class="form-row">
        <label for="password">Lösenord</label>
        <input
          type="password"
          id="password"
          name="password"
          placeholder=""
          required
          autofocus
        />
        <button type="submit" class="btn btn-accent btn-ghost inverted mt-2">
          <span>Läs</span>
        </button>
      </div>
    </form>
    <h4 class="message accent" v-if="showMessage">{{ this.message }}</h4>
  </div>
</template>

<style lang="scss">
.hidden {
  display: none;
}

.form {
  flex-direction: column;
  align-items: flex-start;

  .emphasize {
    color: var(--accent-color);
    text-decoration: underline;
  }

  .h1 {
    font-size: 1.3rem;
  }

  section {
    max-width: 420px;
  }

  form {
    width: 300px;
    display: flex;
    flex-direction: column;

    fieldset {
      border: none;
      padding: 0;
      margin: 0;
    }

    .form-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      .info {
        font-size: 0.8rem;
        padding-left: 8px;
        margin: 0;
      }
    }

    .button {
      background: var(--accent-color);
      color: #fff;
      border: none;
      display: block;
      text-align: center;
      padding: 8px;
      text-decoration: none;
      width: 90px;
      font-size: inherit;
      font-family: inherit;

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;

        &:hover {
          text-decoration: none;
        }
      }
    }

    input {
      padding: 8px;
      font-family: inherit;

      &:focus {
        outline: none;
        box-shadow: 0 0 0px 1px var(--accent-color);
        border-color: transparent;
      }
    }
  }
}

.form-message {
  display: none;

  &--show {
    display: block;
  }

  &-success {
    color: darkgreen;
  }

  &-error {
    color: var(--accent-color);
  }
}
</style>

<script>
export default {
  data: () => ({
    showMessage: false,
    message: ""
  }),
  methods: {
    onSubmit: async function(e) {
      this.showMessage = false;
      const password = e.currentTarget.elements.password.value;
      if (password !== undefined && password !== null && password !== "") {
        const button = this.$el.querySelector("button[type='submit']");
        if (password === null || password === "") {
          this.message = "Ange ett lösenord.";
          this.showMessage = true;
          return false;
        }

        button.disabled = true;
        const data = {
          password
        };

        await fetch("/.netlify/functions/handbook", {
          method: "POST",
          body: JSON.stringify(data)
        })
          .then(res => {
            if (res.ok) {
              return res.blob();
            }

            if (res.status === 401) {
              this.message = "Felaktigt lösenord.";
            } else {
              this.message = "Något gick fel. Försök igen senare.";
            }
            this.showMessage = true;
            return false;
          })
          .then(blob => {
            if (blob !== null && blob !== false) {
              const url = window.URL.createObjectURL(blob);

              window.location = url;
              // const a = document.createElement("a");
              // a.href = url
              // a.download = "LU-Handbok_2023.pdf";
              // document.getElementById("app").appendChild(a);
              // a.click();
              // a.remove();
            }
          })
          .finally((button.disabled = false));
      }
    }
  }
};
</script>
