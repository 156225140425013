<template>
  <div class="menu-item">
    <p>
      <span class="chinese">{{ item.name.zh }}</span>
      <template v-if="item.price > 0">
        <span class="price">
          {{ item.price }}.-{{
            item.quantity > 1 ? ` (${item.quantity} st.)` : ""
          }}
        </span>
      </template>
    </p>
    <p>{{ item.name.sv }}</p>
    <p v-if="item.name.en" v-html="item.name.en" />
  </div>
</template>

<style lang="scss" scoped>
.menu-item {
  padding-bottom: 30px;
  p {
    &:first-child {
      font-size: 1.1rem;
    }
    &:first-child,
    &:first-child + p {
      margin: 0;
      &:not(:last-child) {
        color: var(--accent-color);
      }
    }
    margin-top: 0;
    padding-left: 0;
  }

  .chinese + .price {
    margin-left: 8px;
  }
}
</style>

<script>
export default {
  props: {
    item: {}
  }
};
</script>
