<template>
  <article class="menu">
    <p>
      <router-link :to="`/${$route.params.id}`">Tillbaka</router-link>
    </p>
    <h1 class="mb-0">菜單</h1>
    <h2 class="mt-0">Take away-meny</h2>

    <p class="mb-3">
      Ring och beställ:
      <a class="accent bold" href="tel:040319898">040 &mdash; 31 98 98</a>
    </p>

    <section class="menu-container">
      <template v-if="this.menu.length === 0">
        <span class="accent">Hämtar meny...</span>
      </template>
      <menu-category
        v-for="(category, index) in this.menu"
        :key="`category-${index}`"
        :category="category"
      />
    </section>
  </article>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import MenuCategory from "../components/MenuCategory.vue";

export default {
  components: { MenuCategory },
  data: () => ({
    menu: []
  }),
  methods: {
    getMenu() {
      const menuRef = firebase.database().ref("menu");
      return menuRef.once("value").then(snapshot => {
        const value = Object.values(snapshot.val());
        value.forEach(category => {
          if (category.items) category.items = Object.values(category.items);
        });
        return (this.menu = value
          .filter(c => c.items)
          .sort((a, b) => (a.order > b.order ? 1 : -1)));
      });
    }
  },
  beforeMount() {
    this.getMenu();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 2rem;
  font-weight: 700;
}
h1 + h2 {
  font-size: 1rem;
}

.text-vertical {
  writing-mode: vertical-lr;
  text-orientation: upright;
}

.menu {
  flex-direction: column;
  align-items: flex-start;
}
</style>
