<template>
  <article class="menu">
    <p>
      <router-link :to="`/${$route.params.id}`">Tillbaka</router-link>
    </p>

    <h1 class="mb-0">午餐</h1>
    <h2 class="mt-0">Lunch</h2>

    <p class="mb-1">
      Vi serverar lunch alla helgfria vardagar 11:30 - 14:30. Menyn består av
      våra grillade signaturer, minst en vegansk rätt och en veckans special.
      Alla rätter går utmärkt att få som Take away.
    </p>

    <p>
      Ring in din beställning
      <a class="bold inherit-color" href="tel:040319898"
        >040 &mdash; 31 98 98</a
      >
    </p>

    <section class="menu-container">
      <div class="menu-category mb-4">
        <h2>午餐 Veckans speciallunch</h2>
        <p class="body">
          Varje vecka har vi en ny rolig rätt att servera.
          <a href="https://www.instagram.com/koket.lu"
            >Följ oss på Instagram för menyuppdateringar.</a
          >
        </p>
      </div>
      <div class="menu-category">
        <menu-item
          v-for="(item, index) in this.items"
          :key="index"
          :item="item"
        />
      </div>
    </section>
  </article>
</template>

<style lang="scss" scoped>
h1 {
  font-size: 2rem;
  font-weight: 700;
}
h1 + h2 {
  font-size: 1rem;
}
</style>

<script>
import MenuItem from "../components/MenuItem.vue";
export default {
  components: { MenuItem },
  data: () => ({
    items: [
      {
        name: {
          sv: "Grillad anka med ris/äggnudlar",
          zh: "燒鴨飯/蛋麵",
          en: "Roast duck with rice/egg noodles"
        },
        price: 135,
        quantity: 0
      },
      {
        name: {
          sv: "Siu Yuk - Krispigt grillat sidfläsk med ris/äggnudlar",
          zh: "燒肉飯/蛋麵",
          en: "Crispy roasted pork belly with rice/egg noodles"
        },
        price: 130,
        quantity: 0
      },

      {
        name: {
          sv:
            "Sichuankökets favvis i en vegansk version: - Mapo Tofu! Het och kryddig tofu-gryta med sojafärs, Serveras med ris",
          zh: "麻婆豆腐",
          en: "Mapo Tofu in a vegan version"
        },
        price: 130,
        quantity: 0
      },
      {
        name: {
          sv: "En vitlökssprängd grönsakswok med tofu och shiitake svamp",
          zh: "時菜香飯",
          en: "Vegan of the season"
        },
        price: 130,
        quantity: 0
      }
    ]
  })
};
</script>
